import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Check as CheckIcon } from "../icons/check";
import { Chevron as ChevronIcon } from "../icons/chevron";

export type SortOption<T = string> = {
  name: string;
  value?: T;
};

export default function Dropdown({
  options,
  selectedOption,
  setSelectedOption,
  width,
  vPad = "3",
  text = "base",
}: {
  options: SortOption[];
  selectedOption: any;
  setSelectedOption: any;
  width?: string | null;
  vPad?: string;
  text?: string;
}) {
  return (
    <div className={`${width ? "w-" + width : "w-52"} z-10`}>
      <Listbox
        value={selectedOption?.name}
        onChange={(value: string) => {
          setSelectedOption(
            options?.filter((val: any) => val.name === value)[0]
          );
        }}
      >
        <div className="relative">
          <Listbox.Button
            className={`relative w-full cursor-default font-medium transition hover:bg-neutral-100 rounded-xl bg-white dark:bg-neutral-800 dark:border-neutral-600 dark:text-white py-${vPad} pl-3 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
          >
            <span className={`flex items-center gap-2 truncate text-${text}`}>
              {selectedOption?.name}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronIcon size={32} />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-neutral-800 py-1  text-${text} shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
            >
              {options?.map((option: any, optionIdx: number) => {
                return (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-9 pr-4 ${
                        active
                          ? "bg-neutral-100 font-medium dark:bg-neutral-700 dark:text-neutral-200"
                          : "text-gray-900 dark:text-white"
                      }`
                    }
                    value={option.name}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`flex items-center truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option?.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-black dark:text-white">
                            <CheckIcon />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
